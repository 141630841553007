// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models

import { environment } from '../../../../../environments/environment';
import { UserSizeItemModel } from '../models/user-size.model';

@Injectable()
export class UserSizeService {
	/**
	 * Service Constructor
	 *
	 * @param http: HttpClient
	 */
    constructor(private http: HttpClient) { }

	/**
	 * Returns data from fake server
	 */
    getAllItems(): Observable<UserSizeItemModel[]> {
        var item = localStorage.getItem('currentUser');
        var obj = JSON.parse(item);
        if (obj.id) {
            return this.http.get<UserSizeItemModel[]>(`${environment.apiUrl}/api/usersizes/getsizesforuser?userId=` + obj.id);
            //return this.http.get<UserSizeItemModel[]>(`https://api.tshopmanager.com/api/usersizes/getsizesforuser?userId=` + obj.id);
        }
        else {
            alert('User is not logged in');
        }
    }
}
