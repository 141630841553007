// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../../core/reducers/';
import { currentUser } from '../../../auth/_selectors/auth.selectors';

@Injectable()
export class LicenseGuard implements CanActivate {
    constructor(private store: Store<AppState>, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;
        if (currentUser) {
            // if (currentUser.trialExpired && !currentUser.accountActive && !route.data.isLicense) {
            //     // role not authorised so redirect to home page
            //     this.router.navigate(['/selectpackage']);
            //     return false;
            // }
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigateByUrl('/auth/login');
        return false;
    }
}
