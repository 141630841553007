// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class PaymentService {
    HeaderOption = new HttpHeaders({
        'Content-Type': 'application/json',
    })
    constructor(private http: HttpClient) { }

    savePayment(payment: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/payment/processonetimepayment', payment, {
                headers: this.HeaderOption
            });
    }

    depositPayment(payment): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/payment/processdepositpayment', payment)
    }

    getPaymentSetupStatus(Id: any): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/payment/checkpaymentsetup?userId=' + Id, {
                headers: this.HeaderOption
            })
    }

}
