// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { StateObservable } from '@ngrx/store';

@Injectable()
export class QuoteService {
    HeaderOption = new HttpHeaders({
        'Content-Type': 'application/json',
    })
    constructor(private http: HttpClient) { }

    saveQuote(Quote: any): Observable<any> {
        return this.http
			.post<any>(environment.apiUrl + '/api/quote/addnewquote', Quote, {
                headers: this.HeaderOption
            });
    }

    updateQuote(Quote: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/quote/updatequote', Quote, {
                headers: this.HeaderOption
            });
    }

    getQuotes(Id: number): Observable<any> {
        return this.http
			.get<any>(environment.apiUrl + '/api/quote/getquotesforuser?userId=' + Id);
    }

    deleteQuotes(Id: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/quote/removequote', Id);
    }

    getOne(Id) {
        return this.http
            .get<any>(environment.apiUrl + '/api/quote/getsinglequote?quoteId=' + Id);
    }

    getQuoteDetail(Id: number): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/quote/getsinglequotedetail?quoteId=' + Id);
    }

    getQuoteDetailByToken(token: string): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/quote/getsinglequotedetailbytoken?quotetoken=' + token);
    }

    updateQuoteStatus(data): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/quote/updatequotestatus', data, {
                headers: this.HeaderOption
            });
    }
    getQuoteStatus(Id: any): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/quote/checkquotestatus?quoteId=' + Id);
    }

    getQuoteStatusByToken(token: string): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/quote/checkquotestatusbytoken?quoteId=' + token);
    }

    resendQuote(Id: any): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/quote/resendquote?quoteId=' + Id);
    }

    convertToInvoice(data: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/quote/movequotetoinvoice', data);
    }

    addDocument(data): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/quote/adddocument', data)
    }
}
