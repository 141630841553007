// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class PricegridService {
    HeaderOption = new HttpHeaders({
        'Content-Type': 'application/json',
    })
    constructor(private http: HttpClient) { }


    getPricegrids(Id: number): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/PriceGrid/getallpricegrids?userID=' + Id);
    }

    savePricegrid(data: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/pricegrid/addnewpricegrid', data);
    }

    updatePricegrid(data: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/pricegrid/updatepricegrid', data);
    }

    deletePricegrid(data: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/pricegrid/removepricegrid', data)
    }

    getSinglePricegrid(id: any): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/PriceGrid/getsinglepricegrid?pricegridid=' + id);
    }

}
