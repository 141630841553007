import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageModalComponent } from '../../../../controls/message-modal/message-modal.component';
import { MatDialog } from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {

    constructor(private _snackBar: MatSnackBar, public dialog: MatDialog) {
    }

    openSnackBar(type: string, message: string) {
        this._snackBar.open(message, '', {
            duration: type == 'error' ? 3000 : 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: [type == 'error' ? 'red-snackbar' : 'green-snackbar'],
        });
    }

    openModal(message: string) {
        const dialogRef = this.dialog.open(MessageModalComponent, {
            maxWidth: '500px',
            data: { message: message },
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
        })
    }
}
