// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { InvoiceStatusItemModel } from '../models/invoicestatus.model';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class InvoiceStatusService {
	/**
	 * Service Constructor
	 *
	 * @param http: HttpClient
	 */
    constructor(private http: HttpClient) { }

	/**
	 * Returns data from fake server
	 */
    getAllItems(): Observable<InvoiceStatusItemModel[]> {
		var currentUser = localStorage.getItem('currentUser');
		var userId = JSON.parse(currentUser).id;
        return this.http.get<InvoiceStatusItemModel[]>(environment.apiUrl + '/api/settings/getinvoicestatusbyuser?userId='+userId)
        //return this.http.get<DataTableItemModel[]>('http://api.tshopmanager.com/api/Tenant/gettenantcustomers');
    }
}
