// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class LocationService {
    HeaderOption = new HttpHeaders({
        'Content-Type': 'application/json',
    })
    constructor(private http: HttpClient) { }

    save(Location: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/userlocation/addnewlocation', Location, {
                headers: this.HeaderOption
            });
    }

    update(Location: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/userlocation/updatelocation', Location, {
                headers: this.HeaderOption
            });
    }

    getOne(Id: number): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/userlocation/getsinglelocation?locationId' + Id);
    }

    delete(Id: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/userlocation/removelocation', Id);
    }

    getAll(Id) {
        return this.http
            .get<any>(environment.apiUrl + '/api/userlocation/getlocationsbyuser?userid=' + Id);
    }

}
