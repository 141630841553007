// Angular
import {
    Component,
    OnInit,
    Inject,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    OnDestroy
} from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormArray,
    FormControl,
    ValidatorFn
} from "@angular/forms";
// Material
import { MatDialogRef, MAT_DIALOG_DATA, MatError } from "@angular/material";
// RxJS
//import { Subscription, of } from 'rxjs';
import { of, Subscription } from "rxjs";
import { delay, map } from "rxjs/operators";

// NGRX
import { Update } from "@ngrx/entity";
import { Store, select } from "@ngrx/store";
// State
import { AppState } from "../../../../../../app/core/reducers";
// CRUD
import { TypesUtilsService } from "../../../../../core/_base/crud";
import { environment } from "../../../../../../environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { SubstrateProductViewModel } from "../../../../../core/_base/layout/models/substrate-product.model";
import {
    SubstrateProductUpdated,
    SubstrateProductOnServerCreated
} from "../../../../../core/e-commerce/_actions/customer.actions";

import { UserSizeService } from "../../../../../core/_base/layout/services/user-size.service";
import { UserSizeItemModel } from "../../../../../core/_base/layout/models/user-size.model";

@Component({
    // tslint:disable-next-line:component-selector
    selector: "kt-substrate-product-edit-dialog",
    templateUrl: "./products-edit.dialog.component.html",
    //changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SubstrateProductEditDialogComponent implements OnInit, OnDestroy {
    // Public properties
    customer: SubstrateProductViewModel;
    customerForm: FormGroup;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    isFormReady: boolean = false;
    // Private properties
    private componentSubscriptions: Subscription;
    //typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];
    sizes: any[];
    interests: any;
    items: FormArray;
    ResultData: any[] = [];
    ordersData = [];
    formControls;
    //musicPreferences = [
    //    { id: 1, genre: 'Pop' },
    //    { id: 2, genre: 'Rock' },
    //    { id: 3, genre: 'Techno' },
    //    { id: 4, genre: 'Hiphop' }
    //];
    musicPreferencesApiRes: any;
    //musicPreferences = [];

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<CustomerEditDialogComponent>
     * @param data: any
     * @param fb: FormBuilder
     * @param store: Store<AppState>
     * @param typesUtilsService: TypesUtilsService
     */
    constructor(
        public dialogRef: MatDialogRef<SubstrateProductEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private store: Store<AppState>,
        private typesUtilsService: TypesUtilsService,
        private http: HttpClient,
        private userSizesService: UserSizeService
    ) {
        //this.http.get<UserSizeItemModel[]>(`https://api.tshopmanager.com/api/usersizes/getsizesforuser?userId=` + 2).subscribe(
        //    (result) => {
        //        console.log(result);
        //        this.musicPreferencesApiRes = result;
        //        result.map((o: any) => { this.musicPreferences.push(this.fb.control({ value: o.value, disabled: !o.available })) })
        //    }
        //)
        //  this.formControls = this.musicPreferences.map(control => new FormControl(false));
        //  this.http.get<UserSizeItemModel[]>(`https://api.tshopmanager.com/api/usersizes/getsizesforuser?userId=` + 2).subscribe(
        //      (result) => {
        //          result.forEach(obj => {
        //              var newPreference = {
        //                  "id": obj.id,
        //"genre": obj.sizeName
        //              };
        //              console.log(obj);
        //              this.musicPreferences.push(newPreference);
        //              this.formControls.push(new FormControl(false));
        //          });
        //          console.log(this.musicPreferences);
        //      }
        //  )
        //        const selectAllControl = new FormControl(false);
        //of(this.getOrders()).subscribe(orders => {
        //    this.ordersData = orders;
        //    this.addCheckboxes();
        //});
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        //this.store.pipe(select(selectCustomersActionLoading)).subscribe(res => this.viewLoading = res);
        if (this.data) {
            this.customer = this.data.customer;
        }
        this.GetData();
        //  this.createForm();
    }

    //GetData() {
    //    this.http
    //        .get<UserSizeItemModel[]>(
    //            `https://api.tshopmanager.com/api/usersizes/getsizesforuser?userId=` + 2
    //        )
    //        .subscribe(result => {
    //            result.forEach(obj => {
    //                console.log(obj);
    //            });
    //            this.ResultData = result;
    //            this.formControls = result.map(control => new FormControl(false));

    //            this.createForm();
    //        });
    //}

    onChange($event, model) {
        console.log($event.checked);
        if (model) {
            model.selected = $event.checked;
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    createForm(result) {
        if (this.customer.price) {
            this.formControls = this.customer.productSizes.map(control => new FormControl(control.selected));
            //this.formControls = result.map(control => new FormControl(false));
            this.customerForm = this.fb.group({
                name: [this.customer.name, Validators.required],
                sku: [this.customer.sku, Validators.required],
                description: [this.customer.description, Validators.required],
                price: [this.customer.price.toString(), Validators.required],
                musicPreferences: new FormArray(this.formControls)
            });
        } else {
            this.formControls = result.map(control => new FormControl(false));
            this.customerForm = this.fb.group({
                name: [this.customer.name, Validators.required],
                sku: [this.customer.sku, Validators.required],
                description: [this.customer.description, Validators.required],
                price: [this.customer.price, Validators.required],
                musicPreferences: new FormArray(this.formControls)
            });
            this.isFormReady = true;
        }
        this.Onchanges();
    }

    get musicPreferences() {
        return this.customerForm.get("musicPreferences") as FormArray;
    }

    GetData() {
        var item = localStorage.getItem('currentUser');
        var obj = JSON.parse(item);
        this.http
            .get<UserSizeItemModel[]>(
                `https://api.tshopmanager.com/api/usersizes/getsizesforuser?userId=` + obj.id
            )
            .subscribe(result => {
                result.forEach(obj => {
                    console.log(obj);
                });
                this.ResultData = result;
                //this.formControls = result.map(control => new FormControl(false));

                this.createForm(result);
            });
    }

    Onchanges() {
        this.customerForm.get("musicPreferences").valueChanges.subscribe(val => {
            console.log(val);
        });
    }

    /**
     * Returns page title
     */
    getTitle(): string {
        if (this.customer.id > 0) {
            return `Edit '${this.customer.name} '`;
        }

        return "New Substrate Product";
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.customerForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    /** ACTIONS */

    /**
     * Returns prepared customer
     */
    prepareCustomer(): SubstrateProductViewModel {
        const controls = this.customerForm.controls;
        const _customer = new SubstrateProductViewModel();

        _customer.id = this.customer.id;

        _customer.sku = controls["sku"].value;
        _customer.name = controls["name"].value;
        _customer.description = controls["description"].value;
        _customer.price = controls["price"].value;
        //  _customer.productsizes = controls['musicPreferences'].value;

        console.log(this.ResultData);

        var index = 0;
        //this.ResultData.forEach(obj => {
        this.ResultData.forEach(obj => {
            console.log(obj);
            var isSelected = controls["musicPreferences"].value[index];
            obj.selected = isSelected;
            index++;
        });
        //_customer.productsizes = this.ResultData;
        _customer.productSizes = this.ResultData;
        return _customer;
    }

    /**
     * On Submit
     */
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.customerForm.controls;

        /** check form */
        //if (this.customerForm.invalid) {
        //    Object.keys(controls).forEach(controlName =>
        //        controls[controlName].markAsTouched()
        //    );

        //    this.hasFormErrors = true;
        //    return;
        //}

        var item = localStorage.getItem("currentUser");
        var obj = JSON.parse(item);

        const editedCustomer = this.prepareCustomer();

        editedCustomer.UserId = obj.id;

        if (editedCustomer.id > 0) {
            this.updateCustomer(editedCustomer);
        } else {
            this.createCustomer(editedCustomer);
        }
    }

    /**
     * Update customer
     *
     * @param _customer: CustomerModel
     */
    updateCustomer(invoiceStatus: SubstrateProductViewModel) {
        const updateCustomer: Update<SubstrateProductViewModel> = {
            id: invoiceStatus.id,
            changes: invoiceStatus
        };
        this.store.dispatch(
            new SubstrateProductUpdated({
                partialCustomer: updateCustomer,
                customer: invoiceStatus
            })
        );

        this.http.post('https://api.tshopmanager.com/api/substrateproducts/updatesubstrateproduct',
        //this.http.post(`https://localhost:44394/api/substrateproducts/updatesubstrateproduct`,
                invoiceStatus,
                {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json"
                    })
                }
            )
            .pipe(
                map(data => {
                    this.dialogRef.close({ invoiceStatus, isEdit: true });
                })
            )
            .subscribe(result => {
                console.log(result);
            });
    }

    /**
     * Create customer
     *
     * @param _customer: CustomerModel
     */
    createCustomer(invoiceStatus: SubstrateProductViewModel) {
        var item = localStorage.getItem("currentUser");
        var obj = JSON.parse(item);

        invoiceStatus.UserId = obj.id;
        console.log(invoiceStatus);

        if (!invoiceStatus.id) {
            invoiceStatus.id = 0;
        }

        this.store.dispatch(
            new SubstrateProductOnServerCreated({ customer: invoiceStatus })
        );

        console.log(invoiceStatus);

        //this.http.post(`${environment.apiUrl}/api/substrateproducts/addnewsubstrateproduct`, invoiceStatus, {
        this.http
            .post(
                `https://api.tshopmanager.com/api/substrateproducts/addnewsubstrateproduct`,
                invoiceStatus,
                {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json"
                    })
                }
            )
            .pipe(
                map(data => {
                    this.dialogRef.close({ invoiceStatus, isEdit: false });
                })
            )
            .subscribe(result => {
                console.log(result);
            });
    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    // async orders
    //of(this.getOrders()).subscribe(orders => {
    //    this.ordersData = orders;
    //    this.addCheckboxes();
    //})

    getOrders() {
        //this.http.get<UserSizeItemModel[]>(`https://api.tshopmanager.com/api/usersizes/getsizesforuser?userId=` + 2).subscribe(
        //    (orders) => {
        //        //this.ordersData = orders;
        //        return orders;
        //    }
        //)

        return [
            { id: 100, name: "order 1" },
            { id: 200, name: "order 2" },
            { id: 300, name: "order 3" },
            { id: 400, name: "order 4" }
        ];
    }
}

function minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
        const totalSelected = formArray.controls
            .map(control => control.value)
            .reduce((prev, next) => (next ? prev + next : prev), 0);

        return totalSelected >= min ? null : { required: true };
    };

    return validator;
}
