import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService } from '../../../../core/auth';
import { AuthenticationService } from '../../../../_services/authentication.service'
import { ConfirmPasswordValidator } from '../register/confirm-password.validator';

@Component({
  selector: 'kt-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  // Public params
  forgotPasswordForm: FormGroup;
  loading = false;
  errors: any = [];

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    public authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private AuthenticationService: AuthenticationService,
    private route: ActivatedRoute,
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    if (this.route.snapshot.params.token) {
      this.initRegistrationForm();
    } else {
      this.router.navigateByUrl('/auth/login');
    }

  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  initRegistrationForm() {
    this.forgotPasswordForm = this.fb.group({
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])]
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });
  }


  submit() {
    const controls = this.forgotPasswordForm.controls;

    if (this.forgotPasswordForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.loading = true;

    const data = { ResetPasswordToken: this.route.snapshot.params.token, Password: controls['password'].value };
    this.AuthenticationService.resetPassword(data).pipe(
      tap(response => { }),
      takeUntil(this.unsubscribe),
      finalize(() => {
        this.loading = false;
        this.cdr.markForCheck();
      })
    ).subscribe(
      res => {
        this.authNoticeService.setNotice("Password reset succcessfully", 'success');
        this.router.navigateByUrl('/auth/login');
      },
      err => {
        this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.NOT_FOUND', { name: this.translate.instant('AUTH.INPUT.EMAIL') }), 'danger');
      }
    );
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.forgotPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) &&
      (control.dirty || control.touched);
    return result;
  }
}
