import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class InvoiceService {
    HeaderOption = new HttpHeaders({
        'Content-Type': 'application/json',
    })
    constructor(private http: HttpClient) { }

    saveInvoice(Invoice: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/invoice/addnewinvoice', Invoice, {
                headers: this.HeaderOption
            });
    }

    updateInvoice(Invoice: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/invoice/updateinvoice', Invoice, {
                headers: this.HeaderOption
            })
    }

    getInvoices(Id: number): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/invoice/getinvoicesforuser')
    }

    deleteInvoice(Id: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/invoice/removeinvoice', Id)
	}

	markInvoiceAsPaid(Id: any): Observable<any> {
		return this.http
			.post<any>(environment.apiUrl +  '/api/invoice/markinvoiceaspaid', Id)
	}

    getOne(Id): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/invoice/getsingleinvoice?invoiceId=' + Id);
	}

	getOneByToken(Id): Observable<any> {
		return this.http
			.get<any>(environment.apiUrl + '/api/invoice/getsingleinvoicebytoken?invoiceToken=' + Id);
	}

    resendInvoice(Id): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/invoice/resendinvoice?invoiceId=' + Id);
    }

    getInvoicesInMonth(Id: number): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/invoice/getinvoicesinamonth')
    }

    getJobsForStatus(StatusId: any): Observable<any> {
        var currentUser = localStorage.getItem('currentUser');
        var userId = JSON.parse(currentUser).id;
        return this.http
            .get<any>(environment.apiUrl + "/api/job/getjobsforstatus?userId=" + userId + "&statusId=" + StatusId)
    }

    updateTrackingCode(data: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/job/updatetrackincode', data);
    }

    updateJobStatus(data: any): Observable<any> {
        return this.http
			.post<any>('https://localhost:44394/api/job/updatejobstatus', data);
    }

    downloadPDF(id): Observable<any> {
        return this.http
            .get<any>(environment.apiUrl + '/api/document/generateworkorderdocument?invoiceId=' + id)
    }

    addDocument(data): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/invoice/adddocument', data)
    }
}
