import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class CutomerService {
    HeaderOption = new HttpHeaders({
        'Content-Type': 'application/json',
    })
    constructor(private http: HttpClient) { }

    getAllCustomers(Id: any): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/api/Tenant/gettenantcustomers?userId=' + Id);
    }

    delete(Id: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/tenant/removetenantcustomer', Id)
    }

    saveCustomerQuick(data: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/tenant/addnewtenantcustomerquick', data);
    }

    saveCustomer(data: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/tenant/addnewtenantcustomer', data);
    }

    getSingleCustomer(id: any): Observable<any> {
        return this.http
			.get<any>(environment.apiUrl + '/api/tenant/getsingletenantcustomerdetail?id=' + id);
    }
}
