// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA, MatError } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../app/core/reducers';
// CRUD
import { TypesUtilsService } from '../../../../core/_base/crud';
import { UserColorUpdated, UserColorOnServerCreated } from '../../../../core/e-commerce/_actions/customer.actions';
import { environment } from '../../../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UserColorItemModel } from '../../../../core/_base/layout/models/user-color-model';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-user-color-edit-dialog',
    templateUrl: './user-color-edit.dialog.component.html',
    //changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class UserColorEditDialogComponent implements OnInit, OnDestroy {
    // Public properties
    customer: UserColorItemModel;
    customerForm: FormGroup;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    // Private properties
    private componentSubscriptions: Subscription;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<CustomerEditDialogComponent>
	 * @param data: any
	 * @param fb: FormBuilder
	 * @param store: Store<AppState>
	 * @param typesUtilsService: TypesUtilsService
	 */
    constructor(public dialogRef: MatDialogRef<UserColorEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private store: Store<AppState>,
        private typesUtilsService: TypesUtilsService, private http: HttpClient) {
    }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
    ngOnInit() {
        //this.store.pipe(select(selectCustomersActionLoading)).subscribe(res => this.viewLoading = res);
        this.customer = this.data.customer;
        this.createForm();
    }

	/**
	 * On destroy
	 */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    createForm() {
        this.customerForm = this.fb.group({
            colorName: [this.customer.color, Validators.required]
        });
    }

	/**
	 * Returns page title
	 */
    getTitle(): string {
        if (this.customer.id > 0) {
            return `Edit color '${this.customer.color} '`;
        }

        return 'New Color';
    }

	/**
	 * Check control is invalid
	 * @param controlName: string
	 */
    isControlInvalid(controlName: string): boolean {
        const control = this.customerForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    /** ACTIONS */

	/**
	 * Returns prepared customer
	 */
    prepareCustomer(): UserColorItemModel {
        const controls = this.customerForm.controls;
        const _customer = new UserColorItemModel();
        _customer.id = this.customer.id;

        _customer.color = controls['colorName'].value;
        return _customer;
    }

	/**
	 * On Submit
	 */
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.customerForm.controls;
        //console.log(controls);
        ///** check form */
        //if (this.customerForm.invalid) {
        //    Object.keys(controls).forEach(controlName =>
        //        controls[controlName].markAsTouched()
        //    );

        //    this.hasFormErrors = true;
        //    return;
        //}

        var item = localStorage.getItem('currentUser');
        var obj = JSON.parse(item);

        const editedCustomer = this.prepareCustomer();

        editedCustomer.UserId = obj.id;

        if (editedCustomer.id > 0) {
            this.updateCustomer(editedCustomer);
        } else {
            this.createCustomer(editedCustomer);
        }
    }

	/**
	 * Update customer
	 *
	 * @param _customer: CustomerModel
	 */
    updateCustomer(invoiceStatus: UserColorItemModel) {
        const updateCustomer: Update<UserColorItemModel> = {
            id: invoiceStatus.id,
            changes: invoiceStatus
        };
        this.store.dispatch(new UserColorUpdated({
            partialCustomer: updateCustomer,
            customer: invoiceStatus
        }));

        this.http.post(`${environment.apiUrl}/api/color/updatecolor`, invoiceStatus, {
        //this.http.post(`https://api.tshopmanager.com/api/color/updatecolor`, invoiceStatus, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        }).pipe(map(data => {
            this.dialogRef.close({ invoiceStatus, isEdit: true });
        })).subscribe(result => {
            console.log(result);
        });
    }

	/**
	 * Create customer
	 *
	 * @param _customer: CustomerModel
	 */
    createCustomer(invoiceStatus: UserColorItemModel) {
        var item = localStorage.getItem('currentUser');
        var obj = JSON.parse(item);

        invoiceStatus.UserId = obj.id;
        console.log(invoiceStatus);

        if (!invoiceStatus.id) {
            invoiceStatus.id = 0;
        }


        this.store.dispatch(new UserColorOnServerCreated({ customer: invoiceStatus }));

        //this.http.post(`${environment.apiUrl}/api/color/AddNewColor`, invoiceStatus, {
        this.http.post(`${environment.apiUrl}/api/color/AddNewColor`, invoiceStatus, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        }).pipe(map(data => {
            this.dialogRef.close({ invoiceStatus, isEdit: false });
        })).subscribe(result => {
            console.log(result);
        });
    }


    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }
}
