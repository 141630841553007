import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class ContactService {
    HeaderOption = new HttpHeaders({
        'Content-Type': 'application/json',
    })
    constructor(private http: HttpClient) { }

    contactEmail(data: any): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/contact/sendcontactemail', data);
    }

    search(text): Observable<any> {
        var currentUser = localStorage.getItem('currentUser');
        var userId = JSON.parse(currentUser).id;
        return this.http
            .get<any>(environment.apiUrl + '/api/search/searchterm?text=' + text + '&userId=' + userId);
    }
}
