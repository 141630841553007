import { UserSizeItemModel } from './user-size.model';

export class SubstrateProductViewModel {
    //ID: number;
    //SKU: string;
    //Name: string;
    //Description: string;
    //Price: string;
    //UserId: number
    id: number;
    sku: string;
    name: string;
    description: string;
    price: string;
    UserId: number;
    productSizes: Array<UserSizeItemModel>;
    sizeXS: boolean;
    sizeS: boolean;
    sizeM: boolean;
    sizeL: boolean;
    sizeXL: boolean;
    size2XL: boolean;
    size3XL: boolean;
    size4XL: boolean;
    size5XL: boolean;
}
