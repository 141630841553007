// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { Logout, User } from '../../../../../core/auth';
import { CurrentUser } from '../../../../../core/auth/_models/currentUser';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
    //user$: Observable<User>;
    currentUser: Observable<CurrentUser> = new Observable<CurrentUser>();
	@Input() avatar: boolean = true;
	@Input() greeting: boolean = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
        var item = localStorage.getItem('currentUser');
        var obj = JSON.parse(item);

        if (obj) {
            this.currentUser = obj;
            //this.currentUser.pic = '';
            console.log(obj);
        }

        //this.user$ = this.store.pipe(select(currentUser));
        
	}

	/**
	 * Log out
	 */
    logout() {
		this.store.dispatch(new Logout());
	}
}
