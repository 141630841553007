// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from '../../../../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { delay, map } from 'rxjs/operators';
@Component({
    selector: 'substrate-product-delete-entity-dialog',
    templateUrl: './products-delete.dialog.component.html'
})
export class SubstrateProductDeleteDialogComponent implements OnInit {
    // Public properties
    viewLoading: boolean = false;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
    constructor(
        public dialogRef: MatDialogRef<SubstrateProductDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient
    ) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
    ngOnInit() {
    }

	/**
	 * Close dialog with false result
	 */
    onNoClick(): void {
        this.dialogRef.close();
    }

	/**
	 * Close dialog with true result
	 */
    onYesClick(): void {
        var object = this.data;
        if (this.data) {
            console.log(object);
            delete object.productSizes
            this.http.post(`${environment.apiUrl}/api/substrateproducts/removesubstrateproduct`, object, {
                //this.http.post(`https://api.tshopmanager.com/api/serviceproducts/removeproduct`, object, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                })
            }).pipe(map(data => {
                this.viewLoading = true;
                setTimeout(() => {
                    this.dialogRef.close(true); // Keep only this row
                }, 2500);
            })).subscribe(result => {
                console.log(result);
            });
        }
    }
}
