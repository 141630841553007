// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{ path: 'quote/:id', loadChildren: () => import('../app/views/quotes/quotes.module').then(m => m.QuotesModule) },
	{ path: 'payment/:id', loadChildren: () => import('../app/views/payments/payments.module').then(m => m.PaymentsModule) },
	{ path: 'auth', loadChildren: () => import('../app/views/pages/auth/auth.module').then(m => m.AuthModule) },
	{ path: '', loadChildren: () => import('../app/views/themes/demo1/theme.module').then(m => m.ThemeModule) },
	{ path: 'home', redirectTo: 'home', pathMatch: 'full' },
	{ path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
