// Angular
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatProgressSpinnerModule, MatFormFieldModule, MatDialogModule, MatInputModule, MatButtonModule, MatSlideToggleModule, MatCheckboxModule, MatListModule } from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';

// Angular in memory
//import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Env
import { environment } from '../environments/environment';
// Hammer JS
import 'hammerjs';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// State
import { metaReducers, reducers } from './core/reducers';
// Copmponents
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
// Partials
import { PartialsModule } from './views/partials/partials.module';
// Layout Services
import {
	DataTableService,
	//FakeApiService,
	KtDialogService,
	LayoutConfigService,
	LayoutRefService,
	MenuAsideService,
	MenuConfigService,
	MenuHorizontalService,
	PageConfigService,
	SplashScreenService,
	SubheaderService,
	QuoteService,
	InvoiceService,
	SettingService,
	PricegridService,
	CutomerService,
	LocationService,
	ContactService,
	LicenseGuard, 
	AuthInterceptService
} from './core/_base/layout';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
import { AuthService } from './core/auth';
// CRUD
import { HttpUtilsService, LayoutUtilsService, TypesUtilsService } from './core/_base/crud';
// Config
import { LayoutConfig } from './core/_config/demo1/layout.config';
// Highlight JS
import { HIGHLIGHT_OPTIONS, HighlightLanguage } from 'ngx-highlightjs';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as scss from 'highlight.js/lib/languages/scss';
import * as xml from 'highlight.js/lib/languages/xml';
import * as json from 'highlight.js/lib/languages/json';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

//import { NgbdModalBasicModule } from '../app/controls/modalpopup/modal-basic.module';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from '../app/controls/_modal';
import { InvoiceStatusService } from './core/_base/layout/services/invoicestatus.service';
import { SettingsEditDialogComponent } from './views/pages/settings/settings-edit/settings-edit.dialog.component';
import { InvoiceStatusDeleteDialogComponent } from './views/pages/settings/settings-delete/invoice-status-delete.dialog.component';
import { SubstrateProductService } from './core/_base/layout/services/substrate-product.service';
import { SubstrateProductEditDialogComponent } from './views/pages/products/substrates/substrates-edit/products-edit.dialog.component';
import { SubstrateProductDeleteDialogComponent } from './views/pages/products/substrates/substrates-delete/products-delete.dialog.component';
import { ServiceProductService } from './core/_base/layout/services/service-product.service';
import { ServiceProductDeleteDialogComponent } from './views/pages/products/services/services-delete/products-delete.dialog.component';
import { ServiceProductEditDialogComponent } from './views/pages/products/services/services-edit/products-edit.dialog.component';
import { UserColorService } from './core/_base/layout/services/user-color-service';
import { UserColorDeleteDialogComponent } from './views/pages/settings/colors-delete/user-color-delete.dialog.component';
import { UserColorEditDialogComponent } from './views/pages/settings/colors-edit/user-color-edit.dialog.component';
import { UserSizeService } from './core/_base/layout/services/user-size.service';
import { UserSizeEditDialogComponent } from './views/pages/settings/sizes-edit/sizes-edit.dialog.component';
import { UserSizeDeleteDialogComponent } from './views/pages/settings/sizes-delete/sizes-delete.dialog.component';
import { ConfiramtionModalComponent } from './controls/confiramtion-modal/confiramtion-modal.component';
import { PaymentService, SnackbarService } from './core/_base/layout/index';
import { MessageModalComponent } from './controls/message-modal/message-modal.component';
// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

export function hljsLanguages(): HighlightLanguage[] {
	return [
		{ name: 'typescript', func: typescript },
		{ name: 'scss', func: scss },
		{ name: 'xml', func: xml },
		{ name: 'json', func: json }
	];
}


@NgModule({
	declarations: [AppComponent,
		SettingsEditDialogComponent,
		InvoiceStatusDeleteDialogComponent,
		SubstrateProductEditDialogComponent,
		SubstrateProductDeleteDialogComponent,
		ServiceProductDeleteDialogComponent,
		ServiceProductEditDialogComponent,
		UserColorDeleteDialogComponent,
		UserColorEditDialogComponent,
		UserSizeEditDialogComponent,
		UserSizeDeleteDialogComponent,
		ConfiramtionModalComponent,
		MessageModalComponent],
	imports: [
		FormsModule,
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		//environment.isMockEnabled ? HttpClientInMemoryWebApiModule.forRoot(FakeApiService, {
		//	passThruUnknownUrl: true,
		//	dataEncapsulation: false
		//}) : [],
		NgxPermissionsModule.forRoot(),
		PartialsModule,
		CoreModule,
		OverlayModule,
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
		StoreDevtoolsModule.instrument(),
		AuthModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		MatFormFieldModule,
		ModalModule,
		MatDialogModule,
		MatInputModule,
		MatCheckboxModule,
		MatButtonModule,
		MatListModule,
		ReactiveFormsModule,
		InlineSVGModule.forRoot(),
		// NgbModule.forRoot(),
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory
		}),
		CommonModule
	],
	exports: [],
	providers: [
		AuthService,
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		DataTableService,
		InvoiceStatusService,
		SubstrateProductService,
		ServiceProductService,
		UserColorService,
		UserSizeService,
		SplashScreenService,
		QuoteService,
		InvoiceService,
		SettingService,
		PricegridService,
		CutomerService,
		LocationService,
		ContactService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService], multi: true
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: { languages: hljsLanguages }
		},
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		// template services
		SubheaderService,
		MenuHorizontalService,
		MenuAsideService,
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		PaymentService,
		SnackbarService,
		LicenseGuard, 
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptService, multi: true },
	],
	bootstrap: [AppComponent],
	entryComponents: [
		SettingsEditDialogComponent,
		InvoiceStatusDeleteDialogComponent,
		SubstrateProductEditDialogComponent,
		SubstrateProductDeleteDialogComponent,
		ServiceProductDeleteDialogComponent,
		ServiceProductEditDialogComponent,
		UserColorDeleteDialogComponent,
		UserColorEditDialogComponent,
		UserSizeEditDialogComponent,
		UserSizeDeleteDialogComponent,
		ConfiramtionModalComponent,
		MessageModalComponent
	]
})
export class AppModule {
}
