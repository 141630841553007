// Angular
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { tap, finalize, catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment'
import { Router, ActivatedRoute } from '@angular/router';
import { SnackbarService } from './snackbar.service';
/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class AuthInterceptService implements HttpInterceptor {
    // intercept request and add token
    constructor(private injector: Injector,
        private snackbarService: SnackbarService,
        private router: Router
    ) { }


    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        var authRequest = this.addAuthenticationToken(request);

        return next.handle(authRequest).pipe(
            catchError(err => {
                if (err.status === 401) {
                    this.router.navigateByUrl('/auth/login?returnUrl=' + window.location.pathname)
                    //   this.snackbarService.openSnackBar('error', 'You are not authenticate');
                }
                else if (err.status == 0) {
                    this.snackbarService.openSnackBar('error', 'server connection lost');
                }
                else if (err.status == 404) {
                    this.snackbarService.openSnackBar('error', "Not Found");
                }
                else if (err.status == 400 || err.status == 403) {
                    this.snackbarService.openSnackBar('error', err.error.message);
                }
                else if (err.status == 500) {
                    this.snackbarService.openSnackBar('error', 'Internal Server Error');
                }
                const error = err || err.statusText;
                return throwError(error);
            }),
            finalize(
                () => { }
            )
        )

    }

    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        // If we do not have a token yet then we should not set the header.
        // Here we could first retrieve the token from where we store it.
        var token = localStorage.getItem(environment.authTokenKey);

        if (!token) {
            return request;
        }
        // If you are calling an outside domain then do not add the token.
		if (!request.url.match(environment.apiUrl) && !request.url.match(environment.testApiUrl)) {
            return request;
        }
        return request.clone({
            headers: request.headers.set("Authorization", token)
        });
    }

}
