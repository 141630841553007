import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { isUndefined } from "util";

@Component({
    selector: 'app-title',
    template: '<span></span>'
})
export class TitleComponent {
    constructor(private router: Router, private route: ActivatedRoute, private titleService: Title) {

        this.router.events.subscribe(
            (res: any) => {
                if (res.url == '/') {
                    this.titleService.setTitle('T Shop Manager | Dashboard');
                }
                else {
                    let currentRoute = this.route.root;
                    let title = '';
                    do {
                        const childrenRoutes = currentRoute.children;
                        currentRoute = null;
                        childrenRoutes.forEach(routes => {
                            if (routes.outlet === 'primary') {
                                title = routes.snapshot.data.title;
                                currentRoute = routes;
                            }
                        });
                    } while (currentRoute);
                    if (title !== undefined) {
                        this.titleService.setTitle('T Shop Manager | ' + title);
                    }
                }
            }
        )
    }
}
