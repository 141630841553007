export class DataTableItemModel {
	tenantId: number;
	cModel: string;
	cManufacture: string;
	cModelYear: number;
	cMileage: number;
	cDescription: string;
	cCondition: number;
	cStatus: number;
	cVINCode: string;
	company: string;
	contactPerson: string;
	phone1: string;
	phone2: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	state: string;
	country: string;
	zipCode: string;
	website: string;
	email: string;
	internalNotes: string;
	dateCreated: Date;
	cUserId: number;
}
