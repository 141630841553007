// Angular
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'kt-search-dropdown',
	templateUrl: './search-dropdown.component.html',
})
export class SearchDropdownComponent implements OnInit {
	// Public properties

	// Set icon class name
	@Input() icon: string = 'flaticon2-search-1';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	@Input() type: 'brand' | 'success' | 'warning' = 'success';

	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;

	data: any[];
	result: any[];
	loading: boolean;

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	constructor(private cdr: ChangeDetectorRef,
		private router: Router) {
	}

	/**
	 * On init
	 */
	ngOnInit(): void {
	}

	/**
	 * Search
	 * @param e: Event
	 */
	search(e) {
		if (e.keyCode === 13) {
			if (e.target.value.trim().length > 3)
				this.router.navigate(['/search'], { queryParams: { text: e.target.value.trim() } })
		}
	}

	/**
	 * Clear search
	 *
	 * @param e: Event
	 */
	clear(e) {
		this.data = null;
		this.searchInput.nativeElement.value = '';
	}

	openChange() {
		setTimeout(() => this.searchInput.nativeElement.focus());
	}
}
