import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'kt-confiramtion-modal',
  templateUrl: './confiramtion-modal.component.html',
  styleUrls: ['./confiramtion-modal.component.scss']
})
export class ConfiramtionModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfiramtionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

	ngOnInit() {
		
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(): void {
    this.dialogRef.close(true)

  }
}
