// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { environment } from '../../../../../environments/environment';
import { ServiceProductViewModel } from '../models/service-product.model';

@Injectable()
export class ServiceProductService {
	/**
	 * Service Constructor
	 *
	 * @param http: HttpClient
	 */
    constructor(private http: HttpClient) { }

	/**
	 * Returns data from fake server
	 */
    getAllItems(): Observable<ServiceProductViewModel[]> {
        var item = localStorage.getItem('currentUser');
        var obj = JSON.parse(item);
        if (obj.id) {
            //return this.http.get<ServiceProductViewModel[]>('https://api.tshopmanager.com/api/serviceproducts/getserviceproductsforuser?userId=' + obj.id);
            return this.http.get<ServiceProductViewModel[]>(environment.apiUrl + '/api/serviceproducts/getserviceproductsforuser?userId=' + obj.id);
        }
        else {
            alert('User is not logged in');
        }
    }
}
