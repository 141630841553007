// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models

import { environment } from '../../../../../environments/environment';
import { UserColorItemModel } from '../models/user-color-model';

@Injectable()
export class UserColorService {
	/**
	 * Service Constructor
	 *
	 * @param http: HttpClient
	 */
    constructor(private http: HttpClient) { }

	/**
	 * Returns data from fake server
	 */
    getAllItems(): Observable<UserColorItemModel[]> {
        var item = localStorage.getItem('currentUser');
        var obj = JSON.parse(item);
        if (obj.id) {
            return this.http.get<UserColorItemModel[]>(`${environment.apiUrl}/api/color/getcolorsforuser?userId=` + obj.id);
            //return this.http.get<SubstrateProductViewModel[]>(environment.apiUrl + '/api/substrateproducts/getsubstrateproductsforuser?userId=' + obj.id);
        }
        else {
            alert('User is not logged in');
        }
    }
}
