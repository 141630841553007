// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { DataTableItemModel } from '../models/datatable-item.model';
import { environment } from '../../../../../environments/environment';


const API_DATATABLE_URL = 'api/cars';

@Injectable()
export class DataTableService {
	/**
	 * Service Constructor
	 *
	 * @param http: HttpClient
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Returns data from fake server
	 */
	getAllItems(): Observable<DataTableItemModel[]> {
		var currentUser = localStorage.getItem('currentUser');
		var userId = JSON.parse(currentUser).id;
		return this.http.get<DataTableItemModel[]>(environment.apiUrl + '/api/Tenant/gettenantcustomers?userId=' + userId);
		//return this.http.get<DataTableItemModel[]>('http://api.tshopmanager.com/api/Tenant/gettenantcustomers');
	}
}
