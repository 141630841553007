// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class SettingService {

    constructor(private http: HttpClient) { }

    getOne(Id) {
        return this.http
            .get<any>(environment.apiUrl + '/api/settings/getusersettings?userID=' + Id);
    }

    uploadCompanyLogo(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return this.http
            .post<any>(environment.apiUrl + '/api/settings/uploadcompanylogo', data);
    }

    suspendPayment(userId): Observable<any> {
        return this.http
            .post<any>(environment.apiUrl + '/api/payment/suspendpayment', { UserId: userId });
    }
}
