import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models/user';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/api/users/authenticate`, { username, password })
      // return this.http.post<any>("http://api.tshopmanager.com/api/users/authenticate", { username, password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          var trialStart = user.trialStarted;

		user.trialExpired = false;
		  //temporary commenting out the trial part
        //   if (!user.accountActive && trialStart) {
        //     let expireDate = moment(trialStart).add(7, 'days');
        //     let today = moment()
        //     user.expiredIn = expireDate.diff(today, 'days') + 1;
        //     user.trialExpired = user.expiredIn < 0
        //   } else {
        //     user.trialExpired = true;
        //   }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }

        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  register(user) {

    console.log(user);
    var json = JSON.stringify(user);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<User>(`${environment.apiUrl}/users/register`, json, httpOptions).subscribe(result => {
      //this.http.post<User>('http://api.tshopmanager.com/api/users/register', json, httpOptions).subscribe(result => {
      //console.log(result);
      return result;
    }, error => console.error(error));

    //return this.http.post<any>(`${environment.apiUrl}/users/register`, { user })
    //this.http.post<any>('https://api.tshopmanager.com/api/users/register', { user })
    //.pipe(map(user => {
    //	// login successful if there's a jwt token in the response
    //	if (user && user.token) {
    //		// store user details and jwt token in local storage to keep user logged in between page refreshes
    //		localStorage.setItem('currentUser', JSON.stringify(user));
    //		this.currentUserSubject.next(user);
    //	}

    //	return user;
    //}));
  }

  forgotPassword(email) {
    return this.http
      .post<any>(environment.apiUrl + '/api/users/forgotpassword', { Username: email });
  }

  resetPassword(data) {
    return this.http
      .post<any>(environment.apiUrl + '/api/users/resetpassword', data)
  }
}
